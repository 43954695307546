<style scoped>
    .water-wave-container{
        /* 解决ios上overflow失效问题，失效原因是：其实就是父级设置圆角属性失效，父元素使用border-radius和overflow:hidden做成圆形，子元素如果使用了transform属性，则父元素的overflow:hidden会失效 */
        -webkit-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
    }
    .ray{
        background:radial-gradient(at 80% 20%, #fff 5%, transparent 60%);
    }
    .water-wave{
        width:300%;
        height:300%;
        border-radius:45%;
        animation: water-wave 7s linear infinite;
        transition: top .5s linear;
        top: 100%;
    }
    .water-wave-behind{
        width: 300%;
        height: 300%;
        border-radius: 45%;
        border: 1px solid rgba(255, 255, 255, .3);
        animation: water-wave 7s linear infinite;
        transition: bottom .5s linear;
    }
    @keyframes water-wave{
        0% {transform: translateX(-50%) rotate(0deg);}
        100% {transform: translateX(-50%) rotate(360deg);}
    }
    @keyframes water-wave{
        0% {transform: translateX(-50%) rotate(0deg);}
        100% {transform: translateX(-50%) rotate(360deg);}
    }
    @media (min-width:576px) and (max-width:767px){
        .scale-video .video-container{
            transform-origin: 50% 100%;
            transform: scale(1.3) !important;
            transition: transform .15s linear;
        }
    }
</style>
<template>
    <div class="video-wrapper h-100 w-100">
        <!-- 视频 -->
        <div class="video-container w-100 h-100 position-relative">
            
            <!-- <div v-if="isIOS" class="w-100 h-100 overflow-hidden">
                <easy-player :video-url="easyplayerUrl" ref="easyPlayer" />
            </div> -->
            <div class="w-100 h-100 overflow-hidden" v-html="videoHtml"></div>
            <div v-show="isShowVideoImg" class="w-100 h-100 bg-black position-absolute translate-middle top-50 start-50">
                <img class="img-fluid d-block" :class="imgClass" src="@/assets/images/video/video.jpg"/>
            </div>
            <!-- 视频控件 -->
            <div class="position-absolute bottom-0 end-0 d-flex text-light align-items-end index-1">
                <span class="mb-1 bg-dark bg-opacity-75 p-2 lh-1 me-1 rounded" :title="$t('video.focal')" @click="changeFocal" role="button">
                    <i class="bi bi-camera"></i>
                </span>
                <span class="mb-1 bg-dark bg-opacity-75 p-2 lh-1 me-1 rounded" :title="$t('video.refresh')" @click="initVideo" role="button">
                    <i class="bi bi-arrow-clockwise"></i>
                </span>
            </div>
            <!-- <div v-if="videoState" class="position-absolute start-50 top-55 translate-middle bg-dark rounded-3 py-2 px-3 fw-bold text-light fs-4 text-spacing-_3">{{ videoState }}</div> -->
            <!-- 公告 -->
            <!-- <div :class="noticeClass" class="d-flex align-items-center flex-grow-1 position-absolute top-0 start-0 end-0 bg-dark bg-opacity-50 text-light">
                <i class="bi bi-megaphone-fill mx-2 py-1 text-warning"></i>
                <div v-if="$route.name!='share'" class="flex-grow-1 notice-short d-flex px-2 overflow-hidden" role="button">
                    <div class="w-100 rounded-pill position-relative width-max marquee text-nowrap">
                        <label>{{notice}}</label>
                    </div>
                </div>
                <div class="notice-long position-absolute bg-dark bg-opacity-50 p-2 rounded w-100 index-1 top-100">{{notice}}</div>
            </div> -->
        </div>
        <!-- 计时 -->
        <div class="rounded-circle position-absolute p-4 overflow-hidden bg-secondary bg-opacity-75 water-wave-container" :class="timeClass">
            <div class="position-absolute bg-primary bg-opacity-50 water-wave" :style="'top:'+((1-roomInfo.countdown/(roomInfo.second<=0?Infinity:roomInfo.second))*100)+'%'"></div>
            <span class="position-absolute fw-bold fs-4 d-flex flex-column justify-content-center w-100 h-100 top-0 start-0 text-center text-yellow">{{roomInfo.countdown}}</span>
            <!-- <div class="position-absolute water-wave-behind border-1" :style="'bottom:'+((1-countdown/roomInfo.second)>=.45?(9/11*(countdown/roomInfo.second)*100):(9/11*(countdown/roomInfo.second)*100))+'%'"></div> -->
            <!-- <div class="position-absolute bg-info bg-opacity-75 water-wave" :style="'top:'+((1-countdown/roomInfo.second)*100)+'%'"></div>
            <div class="position-absolute water-wave-behind border-1" :style="'bottom:'+(countdown/roomInfo.second*100-20)+'%'"></div> -->
            <div class="position-absolute ray w-100 h-100 top-0 start-0"></div>
        </div>
        <!-- 台信息 -->
        <div class="position-absolute bottom-xs-0 start-xs-0 start-sm-3_75 start-lg-0 top-lg-auto bottom-lg-0 bg-dark text-light px-2 py-1 fw-bold fs-6 rounded m-1 bg-opacity-50" :class="roomClass">
            <div v-if="roomInfo.pokermode==POKER_TYPE.show" class="d-flex border-bottom border-secondary">
                <span class="rounded-pill bg-yellow small px-1 mb-1 fw-bold lh-sm text-dark">明牌</span>
            </div>
            <div class="small">{{roomInfo.room}} {{roomInfo.table}} {{roomInfo.boot}}{{$t("common.boot")}} {{roomInfo.innings}}{{$t("common.bout")}}</div>
        </div>
        
    </div>
</template>
<script>
import {IS_DEBUG,COLOR,REBATE_TYPE,GAME_ID,RATIO,POKER_TYPE} from "@/assets/js/config.js"
import { useLayer } from '@/assets/js/useLayer';
export default {
    emits: ['video-ready','video-error'],
    props: {
        videoList:{
            type:Array,
            default:[]
        },//视频地址，当前支持最多两个
        countdown:0,//倒计时
        timeClass:{
            type:Array,
            default:['top-0','m-2']
        },//计时器的样式，默认没有间距
        imgClass:{
            type:Array,
            default:['h-100','w-100']
        },//图片样式
        roomClass:{
            type:Array,
            default:['top-0','m-2']
        },
        id:"",//视频元素的id
        videoIndex:{
            type:Number,
            default:0
        },//当前播放的视频下标
        // notice:"",//公告内容
        // noticeClass:{
        //     type:Array,
        //     default:['']
        // },//公告样式
    },
    data() {
        return {
            POKER_TYPE,
            videoPlayer:{
                type:null,//类型
                obj:null,//实例
            },//视频播放实例对象
            color:COLOR,//获取颜色配置
            rebate:REBATE_TYPE,//获取佣金方式配置
            currVideoIndex:0,//当前播放的视频下标
            gameIdList:GAME_ID,//获取游戏id列表配置
            isShowVideoImg:true,//是否显示未播放视频之前的图片
            videoHtml:"",//视频html
            isIOS:false,
            easyplayerUrl:"",
            videoState:this.$t("video.videoLoading"),//视频状态字符串
            reloadTimer:null,//用于重新加载视频的定时器
            beginVideoLine:null,//初次使用时的线路id，用于线路不可用时轮询的终点判断
            repeatVideoLineTimer:null,//轮询视频线路定时器
        };
    },
    computed: {
        roomInfo() {
            return this.$store.state.roomInfo;
        },
        videoLineList(){
            return this.$store.state.videoLineList;
        },
		currVideoLine(){
			return this.$store.state.currVideoLine;
		},
    },
    created() {
        this.beginVideoLine=this.$store.state.currVideoLine;
    },
    mounted() {
        this.currVideoIndex=this.videoIndex;
    },
    unmounted() {
        this.closeVideo();
    },
    methods: {
        /**
         * 加载视频
         */
        initVideo(){
            this.closeVideo();
            if(this.videoList.length>0){
                let url=this.videoList[this.currVideoIndex];//直播地址
                // IS_DEBUG&&(url="http://192.168.1.20:7001/live/abc123.flv");
                window.longyuan.testFlv&&(url=window.longyuan.testFlv);//配置文件中有配置地址则使用配置地址
                let scaleClass="";
                this.roomInfo.isar==1&&(scaleClass="scale-top-sm-1_15");
                if ((navigator.userAgent.match(/iphone|ipod|ipad|Macintosh/i))){//苹果端
                    IS_DEBUG&&console.log("使用nodeplayer");
                    //初始化nodeplayer
                    let elem=document.getElementsByClassName("video-container")[0];
                    let elemStyle=getComputedStyle(elem);
                    this.videoHtml='<canvas id="'+this.id+'_video" class="d-block w-100 h-100 '+scaleClass+'" width="'+RATIO*elemStyle.width.replace("px",'')+'" height="'+RATIO*elemStyle.height.replace("px",'')+'"></canvas>';
                    this.$nextTick(()=>{
                        let nodeplayer=new NodePlayer();
                        nodeplayer.setView(this.id+"_video");
                        nodeplayer.setVolume(0);
                        nodeplayer.setBufferTime(0);
                        nodeplayer.start(url);
                        nodeplayer.on("start",()=>{
                            this.isShowVideoImg=false;
                            this.$emit("video-ready",this.id);
                            IS_DEBUG&&console.log("视频加载完成");
                        })
                        nodeplayer.on("error",(e)=>{
                            useLayer({str:this.$t("video.videoErr")});
                            this.isShowVideoImg=true;
                        })
                        this.videoPlayer = {
                            type:"nodeplayer",
                            obj:nodeplayer
                        }
                    })
                }else{//非苹果端
                    IS_DEBUG&&console.log("使用mpegts");
                    //初始化mpegts
                    this.videoHtml='<video id="'+this.id+'_video" class="h-100 w-100 position-sm-absolute '+scaleClass+'" muted autoplay></video>';
                    this.$nextTick(()=>{
                        console.log(url)
                        var player = mpegts.createPlayer({
                            type: 'flv',  // could also be mpegts, m2ts, flv
                            isLive: true,
                            url: url,
                            hasAudio: false
                        },{
                            enableWorker:true,//启用分离线程（DedicatedWorker）进行转译
                            enableStashBuffer:false,//启用 IO 存储缓冲区。如果您需要实时（最小延迟）直播流播放，请设置为 false，但如果网络抖动，可能会停滞。
                            isLive:true,
                            liveBufferLatencyChasing:true,//自动追帧
                            liveBufferLatencyMaxLatency:1,//HTMLMediaElement 中可接受的最大缓冲延迟（以秒为单位）
                            autoCleanupSourceBuffer:true,//自动清理 SourceBuffer
                            reuseRedirectedURL:true,
                            autoCleanupMaxBackwardDuration:10,
                            autoCleanupMinBackwardDuration:5
                        });
                        //接收媒体数据源缓冲结束，说明直播结束
                        player.on(mpegts.Events.LOADING_COMPLETE,(e)=>{
                            this.videoState=this.$t("video.videoEnd");
                            this.reloadTimer= setInterval(() => {this.initVideo()}, 1000);
                        })
                        //接收到媒体信息
                        player.on(mpegts.Events.MEDIA_INFO,(e)=>{
                            this.reloadTimer&&clearInterval(this.reloadTimer);
                            this.videoState="";
                        })
                        //接收到统计信息推送（流状态变化），说明与流服务器的连接没有断开
                        player.on(mpegts.Events.STATISTICS_INFO,(e)=>{
                            if(player.lastDecodedFrame==undefined){//播放对象中的自定义参数“最后一次解码帧”未保存，则保存
                                player.lastDecodedFrame=e.decodedFrames;
                                return;
                            }
                            if(e.currentSegmentIndex==0&&e.decodedFrames==0&&e.speed==0&&player.lastDecodedFrame==0){//如果所有参数都为0，说明地址可用但是没有视频流
                                this.videoState=this.$t("video.videoEnd");
                            }
                        })
                        player.on(mpegts.Events.ERROR, (e)=> {
                            if (e == mpegts.ErrorTypes.NETWORK_ERROR) {
                                this.videoState=this.$t("video.videoUrlErr");
                                // useLayer({str:this.$t("video.videoErr")});
                            }
                            this.isShowVideoImg=true;
                            this.currVideoIndex==0&&this.videoError(this.beginVideoLine);//目前只对第一个视频做操作
                        })
                        player.attachMediaElement(document.getElementById(this.id+'_video'));
                        player.load();
                        player.play().then(()=>{
                            this.isShowVideoImg=false;
                            this.$emit("video-ready",this.id);
                        }).catch(error => {
                            player.unload();
                        });
                        this.videoPlayer = {
                            type:"mpegts",
                            obj:player
                        }
                    });
                }
            }else{
                useLayer({str:this.$t("video.noVideo")});
            }
        },
        /**
         * 关闭视频
         */
        closeVideo(){
            if(this.videoPlayer.obj){//如果已有视频则先释放
                if(this.videoPlayer.type=="nodeplayer"){
                    this.videoPlayer.obj.stop();
                    this.videoPlayer.obj.clearView();
                    // this.videoPlayer.obj.release();
                }else if(this.videoPlayer.type=="mpegts"){
                    this.videoPlayer.obj.destroy();
                }else if(this.videoPlayer.type=="easyplayer"){
                    this.videoPlayer.obj.destroyPlayer();
                }
                this.videoPlayer={type:null,obj:null};
                this.isShowVideoImg=true;
            }
        },
        /**
         * 改变焦距
         */
        changeFocal(){
            if(this.videoList.length<2){//没有可供切换的视频
                useLayer({str:this.$t("video.noFocal")});
                return;
            }
            this.currVideoIndex=this.currVideoIndex==0?1:0;//改变要播放的视频下标
            this.initVideo();
        },
        /**
         * 视频无法播放
         */
        videoError(beginVideoLine){
            let currVideoLineIndex=this.videoLineList.findIndex(v=>v.id==this.currVideoLine);//当前线路下标
            let nextVideoLine=this.videoLineList[currVideoLineIndex==this.videoLineList.length-1?0:currVideoLineIndex+1];//下一个线路
            if(nextVideoLine.id!=beginVideoLine){//当前线路的下一个线路不是初次使用的线路
                this.repeatVideoLineTimer = setTimeout(() => {
                    this.$root.setCurrVideoLine(nextVideoLine.id);
                }, 3000);
				window.cancelChangeLine=()=>{
					this.repeatVideoLineTimer&&clearTimeout(this.repeatVideoLineTimer);
					useLayer({str:this.$t("app.cancelLineAuto")});
				}
                useLayer({str:this.$t("app.willChangeLine")+"<a id='cancelChangeLine' href='javascript:window.cancelChangeLine();'>"+this.$t("room.cancel")+"</a>"});
            }else{
                useLayer({str:this.$t("app.unOtherLine")});
            }
        },
    },
    watch: {
        /**
         * 监听视频列表改变
         */
        videoList(newVal){
            if(!newVal){//当值为null时
                this.closeVideo();
                return;
            }
            this.initVideo();//每当视频列表一改变（即可能改变了线路）就重新加载新的视频流
        },
        /**
         * 监听台面id改变
         */
        "roomInfo.id"(newVal,oldVal){
            layer.closeAll();
            newVal!=oldVal&&this.repeatVideoLineTimer&&clearTimeout(this.repeatVideoLineTimer);
        },
        /**
         * 监听改变当前视频线路
         */
        currVideoLine(){
            this.repeatVideoLineTimer&&clearTimeout(this.repeatVideoLineTimer);
        }
    },
};
</script>