<style scoped>
    .bg{
        background-image: 
        repeating-linear-gradient(45deg, rgba(255,255,255,.2) 0, rgba(255,255,255,.2) 2rem, transparent 2rem,transparent 4rem),
        repeating-linear-gradient(135deg, rgba(255,255,255,.2) 0%, rgba(255,255,255,.2) .5%, transparent .5%,transparent 5%);
    }
    .inset-shadow{
        box-shadow: inset 0 0 .2rem .2rem rgba(255,255,255,.8);
        /* transition: box-shadow 2s linear; */
        transition: scale .25s linear;
    }
    .choose{
        transition: unset;
        scale: 1.11;
        /* box-shadow: inset 0 0 .2rem .2rem rgba(255, 0, 0, 0.3), 0 0 0.2rem 0.5rem #00ffc4;
        background: radial-gradient(#fff, transparent); */
    }
    .flicker>img{
        animation: flicker .7s ease 3 reverse backwards;
    }
    @keyframes flicker{
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    .lucky-item>span>span{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        box-sizing: border-box;
    }
    .lucky-item>span>span:nth-child(1)
    {
        transform:rotate(0deg);
    }
    .lucky-item>span>span:nth-child(2)
    {
        transform:rotate(90deg);
    }
    .lucky-item>span>span:nth-child(3)
    {
        transform:rotate(180deg);
    }
    .lucky-item>span>span:nth-child(4)
    {
        transform:rotate(270deg);
    }
    .choose.lucky-item>span>span::before{
        content: '';
        position: absolute;
        width:100%;
        height: .2rem;
        background: linear-gradient(90deg, transparent 0%, #00ffb3 40%,#00ffb3 85%,transparent 100%);
        /* box-shadow: 0 0 .4rem .05rem #dc3545; */
        top: -0.2rem;
        animation: border-rotate 1s linear infinite;
    }
    @keyframes border-rotate {
        0% {
            transform:scaleX(0);
            transform-origin: left;
        }
        50%
        {
            transform:scaleX(1);
            transform-origin: left;
        }
        50.1%
        {
            transform:scaleX(1);
            transform-origin: right;
        }
        100%
        {
            transform:scaleX(0);
            transform-origin: right;
        }
    }
    .text-shadow{
        text-shadow: 0 0.8rem 0.5rem rgba(0,0,0,0.4), 0.06rem -0.2rem 1px #fff;
    }
    .border-_45rem{
        border-width: .45rem !important;
    }
    .aspect-ratio{
        aspect-ratio: 1/1;
    }
    .billboard{
        transform: rotate(45deg)skew(-20deg,-20deg);
    }
    .billboard>label{
        transform: rotate(-45deg);
    }
    .rays-light::before{
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        mask-image: radial-gradient(rgba(0,0,0,1) 20%, rgba(255,255,255,0) 100%);
        background: repeating-conic-gradient(from 0deg, rgba(255,255,255,.5) 0deg 15deg, transparent 15deg 30deg);
        z-index: 0;
    }
    .rays{
        transform: rotate(-25deg);
        text-shadow: 0 0 0.1rem #000;
    }
    .rays-green{
        color: gold;
    }
    .rays-red{
        color: #ff6f00;
    }
    .rays-red::before{
        background: repeating-conic-gradient(from 0deg, #ff9d64 0deg 15deg, #ff0000 15deg 30deg);
    }
    .rays-green::before{
        background: repeating-conic-gradient(from 0deg, #70ff63 0deg 15deg, #13d500 15deg 30deg);
    }
    .rays::before{
        content: "";
        display: flex;
        width: 200%;
        height: 200%;
        position: absolute;
        left: -50%;
        top: -50%;
        mask-image: radial-gradient(rgba(0,0,0,1), rgba(255,255,255,0) 50%);
    }
    .rays.active::before{
        animation: rays-transform-rotate 10s linear infinite;
    }
    .led{
        background-image: radial-gradient(circle ,#fff 0.1rem,transparent 0.25rem);
        animation: led .8s steps(2,end) infinite alternate;
    }
    .led.active{
        animation: led .1s steps(2,end) infinite alternate;
    }
    @keyframes led {
        0% {
            background-size: 10%;
        }
        100% {
            background-size: 30%;
        }
    }
    .led-left{
        transform-origin: left top;
    }
    .led-right{
        transform-origin: right bottom;
    }
    .rotate-90{
        rotate:90deg;
    }
    .moniter{
        border: 4px solid transparent;
        background-clip: padding-box,border-box;
        background-origin: padding-box,border-box;
        background-image: linear-gradient(to bottom, #212529 20%, #000), linear-gradient(to bottom, #e1d8a5 20%, #5e5400 50%);
        font-family: 'UnidreamLED';
    }
    .moniter-yellow{
        border: 0.3rem groove yellow;
        box-shadow: 0px 0.05rem 0.2rem 0px yellow;
        font-family: 'UnidreamLED';
    }
    .letter-spacing-_01{
        letter-spacing: .01rem;
    }
    .bg-3d{
        box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.7), inset 0 0 3px rgba(252, 252, 252, 0.6);
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0) 50%, rgba(255, 255, 255, .7));
    }
    .btn-3d{
        background-image: linear-gradient(to top, rgba(0, 0, 0, .2), rgba(255, 255, 255, .5));
        transition: transform 50ms;
    }
    .btn-3d:active,.btn-3d.active{
        box-shadow: 
            0 0 0.1rem 0.1rem #3a3a3a, 
            inset 0 1px 1px rgba(255, 255, 255, 0.7), 
            inset 0 0 3px rgba(252, 252, 252, 0.6) !important;
        transform: translateY(.35rem);
    }
    .btn-3d.bg-info.active{
        box-shadow: 
            0 0 0.1rem 0.1rem #3a3a3a, 
            inset 0 1px 1px rgba(255, 255, 255, 0.7), 
            inset 0 0 3px rgba(252, 252, 252, 0.6) !important;
        transform: translateY(.35rem);
    }
    .btn-3d.bg-warning{
        box-shadow: 
            -0.08rem 0.3rem #866709,
            -0.1rem 0.35rem 0 #313131, 
            -0.2rem 0.4rem 0.2rem 0px #000, 
            inset 0 1px 1px rgba(255, 255, 255, 0.7), 
            inset 0 0 3px rgba(252, 252, 252, 0.6);
    }
    .btn-3d.bg-info{
        box-shadow: 
            -0.08rem 0.3rem #076e83,
            -0.1rem 0.35rem 0 #313131, 
            -0.2rem 0.4rem 0.2rem 0px #000, 
            inset 0 1px 1px rgba(255, 255, 255, 0.7), 
            inset 0 0 3px rgba(252, 252, 252, 0.6);
    }
    .btn-start{
        background-image: linear-gradient(to top, rgba(0, 0, 0, .2), rgba(255, 255, 255, .5));
        box-shadow: 
            0rem 0.7rem 0px 0px #9d2732,
            0 0.8rem 0.1rem 0.1rem #313131, 
            inset 0 1px 1px rgba(255, 255, 255, 0.7), 
            inset 0 0 3px rgba(252, 252, 252, 0.6);
    }
    .btn-reset{
        background-image: linear-gradient(to top, rgba(0, 0, 0, .2), rgba(255, 255, 255, .5));
        box-shadow: 
            0rem 0.7rem 0px 0px #aa8618,
            0 0.8rem 0.1rem 0.1rem #313131, 
            inset 0 1px 1px rgba(255, 255, 255, 0.7), 
            inset 0 0 3px rgba(252, 252, 252, 0.6);
    }
    .btn-start:active,.btn-reset:active,.btn-start.active,.btn-reset.active{
        box-shadow: 
            0 0 0.1rem 0.1rem #313131, 
            inset 0 1px 1px rgba(255, 255, 255, 0.7), 
            inset 0 0 3px rgba(252, 252, 252, 0.6);
        transform: translateY(.8rem);
    }
    .board-left{
        transform: rotateY(45deg);
        transform-origin: left;
    }
    .board-right{
        transform: rotateY(-45deg);
        transform-origin: right;
    }
    .border-shadow{
        box-shadow: inset 0px 0px 5px 3px #07778e;
    }
    .lucky-item{
        aspect-ratio: 1/1;
    }
    .text-shadow-light{
        text-shadow: 0px 0px 0.3rem #fff;
    }
    @media (max-width:575px){
        .w-14_2857-xs{
            width: 14.2857%
        }
    }
</style>
<template>
    <div class="d-flex flex-column h-100">
        <!-- 横向导航 -->
        <navbar class="order-first d-flex flex-column" :has-chat-msg="hasChatMsg" :is-fullscreen="isFullscreen" ></navbar>
        <!-- 内容 -->
        <div class="d-flex flex-grow-1 position-relative bg-info justify-content-center bg">
    
            <!-- 操作区 -->
            <div class="d-flex flex-column">
    
                <div class="d-flex justify-content-center align-items-center h-100">
    
                    <!-- 开奖记录 -->
                    <div class="d-none d-sm-flex flex-column h-100 ps-3 py-2">
                        <div class="rounded-3 text-light fw-bold px-2 py-1 mb-2 border-shadow">
                            <label class="rounded-3 px-2 py-1 text-nowrap">开奖记录</label>
                        </div>
                        <div class="flex-grow-1 rounded-3 p-2 d-flex flex-column border-shadow">
                            <div class="bg-dark flex-grow-1 rounded d-flex flex-column h-0 py-1 overflow-y-scroll scroll-none">
                                <div v-for="(item,i) of history" :key="item" class="d-flex px-2 py-1">
                                    <div class="d-flex border border-secondary rounded w-0 flex-grow-1 position-relative small">
                                        <div v-if="item==10" class="bg-info rounded inset-shadow d-flex align-items-center overflow-hidden position-relative lucky-item w-sm-4rem">
                                            <div class="w-100 h-100 rays rays-green active bg-opacity-75 d-flex align-items-center justify-content-center">
                                                <label class="fw-bold index-1">Lucky</label>
                                            </div>
                                        </div>
                                        <div v-else-if="item==22" class="bg-info rounded inset-shadow d-flex align-items-center overflow-hidden position-relative lucky-item w-sm-4rem">
                                            <div class="w-100 h-100 rays rays-red active bg-opacity-75 d-flex align-items-center justify-content-center">
                                                <label class="fw-bold index-1">Lucky</label>
                                            </div>
                                        </div>
                                        <div v-else class="bg-info rounded lucky-item w-sm-4rem p-1 inset-shadow d-flex justify-content-around align-items-center" :class="{'flex-column':luckySetting[item-1].rate}">
                                            <img class="img-fluid" :class="{'w-70':luckySetting[item-1].rate}" :src="require('@/assets/images/room/tiger/'+luckySetting[item-1].code+'.png')" alt="">
                                            <label v-if="luckySetting[item-1].rate" class="lh-1 fw-bold text-shadow-white small">×{{luckySetting[item-1].rate}}</label>
                                        </div>
                                        <i class="text-light position-absolute end-0 bottom-0 lh-1 bg-dark bg-opacity-50 rounded p-1 small">{{history.length-i}}</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex flex-column flex-sm-row justify-content-center h-100">
                        <!-- 屏幕 -->
                        <div class="d-flex flex-column ms-sm-2 h-sm-100 py-1 py-sm-2 px-1 px-sm-0 flex-grow-1">
                            <div class="d-flex rounded-5 p-2 flex-grow-1 border-shadow">
                                <div class="d-flex flex-column justify-content-evenly justify-content-sm-center bg-dark p-2 rounded-3 w-100">
                                    <div class="d-flex align-items-center mb-2">
        
                                        <!-- 赢得积分 -->
                                        <div class="d-flex flex-column col position-relative">
                                            <div class="fw-bold text-light px-2 lh-1 position-absolute top-0 start-50 translate-middle text-shadow-light bg-dark text-nowrap">BOUNS-WIN</div>
                                            <div class="bg-dark text-danger rounded-5 fs-3 lh-sm moniter-yellow text-end px-1 me-2">
                                                <label class="d-flex letter-spacing-_01">
                                                    <span class="d-flex justify-content-end text-secondary text-opacity-25 flex-grow-1 w-0 overflow-hidden text-right"></span>
                                                    <span>{{bounsWin}}</span>
                                                </label>
                                            </div>
                                        </div>
        
                                        <!-- 总积分 -->
                                        <div class="d-flex flex-column col position-relative">
                                            <div class="fw-bold text-light px-2 lh-1 position-absolute top-0 start-50 translate-middle text-shadow-light bg-dark">CREDIT</div>
                                            <div class="bg-dark text-danger rounded-5 fs-3 lh-sm moniter-yellow text-end px-1">
                                                <label class="d-flex letter-spacing-_01">
                                                    <span class="d-flex justify-content-end text-secondary text-opacity-25 flex-grow-1 w-0 overflow-hidden text-right"></span>
                                                    <span>{{$np.minus(userInfo.realCoin,bounsWin)}}</span>
                                                </label>
                                            </div>
                                        </div>
        
                                    </div>
                                    
                                    <div class="bg-dark position-relative rounded-5 overflow-hidden aspect-ratio p-3">

                                        <!-- 上位置 -->
                                        <div class="d-flex small-xs">
                                            <div v-for="item of luckySetting.slice(0,7)" :key="item" class="m-1 w-sm-4rem position-relative col small-xs">
                                                <div class="w-100 position-relative pt-100">
                                                    <div class="position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-around align-items-center lucky-item bg-info rounded p-1 inset-shadow" :class="{'flicker':item.flicker,'choose':item.light,'flex-column':item.rate}">
                                                        <!-- 旋转边框效果 -->
                                                        <span class="position-absolute w-100 h-100">
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                        </span>
                                                        <img class="img-fluid mh-100" :class="{'w-70':item.rate}" :src="require('@/assets/images/room/tiger/'+item.code+'.png')" alt="" />
                                                        <label v-if="item.rate" class="lh-1 fw-bold text-shadow-white small">×{{item.rate}}</label>
                                                        <!-- 叠加个数显示 -->
                                                        <div v-if="item.count>0" class="position-absolute top-0 end-0 rounded-circle bg-primary text-light d-flex justify-content-center align-items-center w-_8rem h-_8rem mt-_15rem me-_15rem">
                                                            <span class="text-center fs-_6rem fw-bold">{{ item.count }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <div class="d-flex">
        
                                            <!-- 左位置 -->
                                            <div class="d-flex flex-column w-14_2857-xs small-xs">
                                                <div v-for="item of luckySetting.slice(-5).reverse()" :key="item" class="m-1 w-sm-4rem position-relative small-xs">
                                                    <div class="w-100 position-relative pt-100">
                                                        <div class="position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-around align-items-center lucky-item bg-info rounded inset-shadow" :class="{'flicker':item.flicker,'choose':item.light,'flex-column':item.rate,'position-relative overflow-hidden p-0':item.code=='lucky','p-1':item.code!='lucky'}">
                                                            <span class="position-absolute w-100 h-100">
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                            </span>
                                                            <div v-if="item.code=='lucky'" class="w-100 h-100 rays rays-red active bg-opacity-75 d-flex align-items-center justify-content-center">
                                                                <label class="fw-bold index-1">Lucky</label>
                                                            </div>
                                                            <img v-else class="img-fluid mh-100" :class="{'w-70':item.rate}" :src="require('@/assets/images/room/tiger/'+item.code+'.png')" alt="">
                                                            <label v-if="item.rate" class="lh-1 fw-bold text-shadow-white small">×{{item.rate}}</label>
                                                            <!-- 叠加个数显示 -->
                                                            <div v-if="item.count>0" class="position-absolute top-0 end-0 rounded-circle bg-primary text-light d-flex justify-content-center align-items-center w-_8rem h-_8rem mt-_15rem me-_15rem">
                                                                <span class="text-center fs-_6rem fw-bold">{{ item.count }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                            <!-- 屏幕中心 -->
                                            <div class="flex-grow-1 rounded bg-info m-2 position-relative overflow-hidden rays-light">
                                                <div class="position-absolute start-0 end-0 top-0 py-1 bg-primary led" :class="{'active':gameState==1}"></div>
                                                <div class="position-absolute start-0 end-0 top-0 py-1 bg-primary led rotate-90 led-left translate-end-y" :class="{'active':gameState==1}"></div>
                                                <div class="position-absolute start-0 end-0 bottom-0 py-1 bg-primary led" :class="{'active':gameState==1}"></div>
                                                <div class="position-absolute start-0 end-0 bottom-0 py-1 bg-primary led rotate-90 led-right translate-start-y" :class="{'active':gameState==1}"></div>
                                                <div class="d-flex justify-content-center">
                                                    <div class="d-flex bg-warning rounded-10 border border-_45rem aspect-ratio billboard">
                                                        <label class="d-flex align-items-center fs-2 text-shadow fw-bold">水果幸运机</label>
                                                    </div>
                                                </div>
                                                <!-- 回合积分 -->
                                                <div class="d-flex flex-column align-items-center justify-content-center position-relative">
                                                    <div class="fw-bold text-secondary small">THIS-ROUND</div>
                                                    <div class="bg-dark text-danger w-8rem rounded-5 fs-3 lh-sm moniter text-end px-1">
                                                        <label class="d-flex">
                                                            <span class="d-flex justify-content-end text-secondary text-opacity-25 flex-grow-1 w-0 overflow-hidden text-right"></span>
                                                            <span>{{currRound}}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <!-- 续押次数显示 -->
                                                <div v-if="keepOnCount>0||true" class="fw-bold text-center">
                                                    <span>续押：</span>
                                                    <span>{{keepOnCount}}</span>
                                                </div>
                                                <!-- 排行和规则 -->
                                                <div class="position-absolute position-sm-relative w-100 top-xs-0 pt-3 pt-sm-1 px-2 px-sm-0 d-flex d-sm-none justify-content-between justify-content-sm-center text-center">
                                                    <i class="bi bi-trophy-fill fs-2 text-yellow mx-2 d-flex" @click="isShowBoard=!isShowBoard"></i>
                                                    <i class="bi bi-patch-question-fill fs-2 text-dark mx-2 d-flex" @click="isShowRule=!isShowRule"></i>
                                                </div>
                                                <!-- 限红 -->
                                                <div v-show="limitred" class="position-absolute bottom-0 start-50 translate-middle-x mb-2 fw-bold text-danger w-100 text-center">单个幸运物限红：{{limitred.join('-')}}</div>
                                            </div>
        
                                            <!-- 右位置 -->
                                            <div class="d-flex flex-column w-14_2857-xs small-xs">
                                                <div v-for="item of luckySetting.slice(7,12)" :key="item" class="m-1 w-sm-4rem position-relative small-xs">
                                                    <div class="w-100 position-relative pt-100">
                                                        <div class="position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-around align-items-center lucky-item bg-info rounded inset-shadow" :class="{'flicker':item.flicker,'choose':item.light,'flex-column':item.rate,'p-1':item.code!='lucky','position-relative overflow-hidden p-0':item.code=='lucky'}">
                                                            <span class="position-absolute w-100 h-100">
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                                <span></span>
                                                            </span>
                                                            <div v-if="item.code=='lucky'" class="w-100 h-100 rays rays-green active bg-opacity-75 d-flex align-items-center justify-content-center">
                                                                <label class="fw-bold index-1">Lucky</label>
                                                            </div>
                                                            <img v-else class="img-fluid mh-100" :class="{'w-70':item.rate}" :src="require('@/assets/images/room/tiger/'+item.code+'.png')" alt="">
                                                            <label v-if="item.rate" class="lh-1 fw-bold text-shadow-white small">×{{item.rate}}</label>
                                                            <!-- 叠加个数显示 -->
                                                            <div v-if="item.count>0" class="position-absolute top-0 end-0 rounded-circle bg-primary text-light d-flex justify-content-center align-items-center w-_8rem h-_8rem mt-_15rem me-_15rem">
                                                                <span class="text-center fs-_6rem fw-bold">{{ item.count }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
        
                                        </div>
        
                                        <!-- 下位置 -->
                                        <div class="d-flex small-xs">
                                            <div v-for="item of luckySetting.slice(12,19).reverse()" :key="item" class="m-1 d-flex justify-content-around align-items-center w-sm-4rem position-relative col small-xs">
                                                <div class="w-100 position-relative pt-100">
                                                    <div class="position-absolute top-0 start-0 end-0 bottom-0 d-flex justify-content-around align-items-center lucky-item bg-info rounded p-1 inset-shadow" :class="{'flicker':item.flicker,'choose':item.light,'flex-column':item.rate}">
                                                        <span class="position-absolute w-100 h-100">
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                        </span>
                                                        <img class="img-fluid mh-100" :class="{'w-70':item.rate}" :src="require('@/assets/images/room/tiger/'+item.code+'.png')" alt="">
                                                        <label v-if="item.rate" class="lh-1 fw-bold text-shadow-white small">×{{item.rate}}</label>
                                                        <!-- 叠加个数显示 -->
                                                        <div v-if="item.count>0" class="position-absolute top-0 end-0 rounded-circle bg-primary text-light d-flex justify-content-center align-items-center w-_8rem h-_8rem mt-_15rem me-_15rem">
                                                            <span class="text-center fs-_6rem fw-bold">{{ item.count }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        <!-- LED灯 -->
                                        <div class="position-absolute start-0 end-0 top-0 py-1 bg-primary led" :class="{'active':gameState==1}"></div>
                                        <div class="position-absolute start-0 end-0 top-0 py-1 bg-primary led rotate-90 led-left translate-end-y" :class="{'active':gameState==1}"></div>
                                        <div class="position-absolute start-0 end-0 bottom-0 py-1 bg-primary led" :class="{'active':gameState==1}"></div>
                                        <div class="position-absolute start-0 end-0 bottom-0 py-1 bg-primary led rotate-90 led-right translate-start-y" :class="{'active':gameState==1}"></div>
        
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <!-- 按钮区 -->
                        <div class="d-flex flex-column justify-content-evenly justify-content-sm-end h-sm-100">
                            <div class="d-none d-sm-flex flex-grow-1 px-3 py-2">
        
                                <!-- 排行榜 -->
                                <div class="d-flex flex-column col me-3">
                                    <div class="fw-bold mb-1 fs-4 text-center text-shadow-white">
                                        <i class="bi bi-stars text-warning"></i>
                                        <i class="ms-1 me-2 text-danger">排行榜</i>
                                        <i class="bi bi-stars text-warning"></i>
                                    </div>
                                    <div class="rounded-5 p-2 border-shadow flex-grow-1 d-flex text-nowrap h-0">
                                        <div class="bg-dark rounded-5 w-100 p-2 overflow-y-scroll scroll-none">
                                            <div v-for="(item,i) of sort" :key="item" :class="{'bg-warning':i==0,'bg-silver':i==1,'bg-coppery':i==2,'bg-info':i>2}" class="d-flex align-items-center mb-1 p-1 rounded-3 fw-bold bg-3d">
                                                <div class="position-relative">
                                                    <i class="d-flex bi bi-award-fill fs-3" :class="{'text-yellow':i==0,'text-secondary':i==1,'text-saddlebrown':i==2,'invisible':i>2}"></i>
                                                    <label class="position-absolute start-0 end-0 text-center small" :class="{'text-warning top-0':i==0,'text-silver top-0':i==1,'text-coppery top-0':i==2,'text-primary text-opacity-75 text-shadow-white fs-6 top-50 translate-middle-y':i>2}">{{i+1}}</label>
                                                </div>
                                                <span class="flex-grow-1 px-2" :class="[i<=2?'text-danger':'text-dark']">{{item.user}}</span>
                                                <div class="d-flex bg-dark bg-opacity-25 rounded-pill px-1">
                                                    <i class="bi bi-coin me-1 text-yellow"></i>
                                                    <span class="text-light">{{item.score}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                                <!-- 玩法说明 -->
                                <div class="d-flex flex-column col">
                                    <div class="fw-bold mb-1 fs-4 text-center text-shadow-white text-primary">
                                        <i class="bi bi-controller"></i>
                                        <i class="ms-1">玩法说明</i>
                                    </div>
                                    <div class="rounded-5 p-2 border-shadow flex-grow-1 d-flex h-0">
                                        <div class="rounded-5 w-100 overflow-y-scroll scroll-none">
                                            <div class="d-flex flex-column small mb-1">
                                                <div class="px-2 position-relative mb-1">
                                                    <div class="bg-primary border border-2 fw-bold text-light text-center w-100 pt-4">
                                                        <label class="bg-primary border-2 border-start border-end w-100 position-absolute translate-middle-y start-0 top-50 rounded-pill lh-1">游戏规则</label>
                                                    </div>
                                                </div>
                                                <div class="text-primary small fw-bold px-2">
                                                    <i class="bi bi-star-fill me-1"></i>
                                                    <span>玩家在8种幸运物中选中任意项，每按一次，对应计数器递增的数为选中的基数数值。每种幸运物下方数字为幸运物被开中时的中奖倍率（注：转盘中有标注的以转盘中的为准）。幸运物选择完成后，按下“开始”按钮，转盘运作随机产生一种幸运物并计算本轮得分。上一轮结束后，若希望保持选中的幸运物及计数器不变的情况下继续押注，则按下对应次数的“续押”按钮。否则可按下“重置”按钮置零所有幸运物计数器。</span>
                                                </div>
                                                <div class="text-primary small fw-bold px-2">
                                                    <i class="bi bi-star-fill me-1"></i>
                                                    <span>本轮得分=被开中的幸运物倍率×幸运物对应计数器计数<span class="text-danger">（所有下注积分不予返还）</span></span>
                                                </div>
                                            </div>
                                            <div class="d-flex flex-column small mb-1">
                                                <div class="px-2 position-relative mb-1">
                                                    <div class="bg-primary border border-2 fw-bold text-light text-center w-100 pt-4">
                                                        <label class="bg-primary border-2 border-start border-end w-100 position-absolute translate-middle-y start-0 top-50 rounded-pill lh-1">快捷操作</label>
                                                    </div>
                                                </div>
                                                <div class="text-primary small fw-bold px-2">
                                                    <i class="bi bi-star-fill me-1"></i>
                                                    <span>8种幸运物从左到右快捷分别对应键盘按键1~8。</span>
                                                </div>
                                                <div class="text-primary small fw-bold px-2">
                                                    <i class="bi bi-star-fill me-1"></i>
                                                    <span>“开始”按钮对应键盘按键“回车(Enter)”。</span>
                                                </div>
                                                <div class="text-primary small fw-bold px-2">
                                                    <i class="bi bi-star-fill me-1"></i>
                                                    <span>“重置”按钮对应键盘按键“0”。</span>
                                                </div>
                                                <div class="text-primary small fw-bold px-2">
                                                    <i class="bi bi-star-fill me-1"></i>
                                                    <span>基数设置可用键盘“+”“-”变更大小。</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                            </div>
        
                            <!-- 基数设置 -->
                            <div class="d-flex align-items-center">
                                <div class="ms-1 me-2 mx-sm-3 d-flex align-items-end h-100 lh-xs-1">
                                    <label class="fw-bold">基数设置</label>
                                </div>
                                <div class="flex-grow-1 d-flex aligh-items-center fw-bold">
                                    <span v-for="item of radixArr" :key="item" class="rounded-pill bg-info px-3 py-sm-1 border border-info me-2 me-sm-3 btn-3d" :class="{'active text-light text-shadow-light':radix==item}" @click="changeRadix(item)" role="button">{{item}}</span>
                                </div>
                                <div class="d-flex flex-column aligh-items-center">
                                    <span class="bg-info px-2 py-sm-1 border border-info me-1 me-sm-3 btn-3d small fw-bold" :class="{'active':!isAnimation}" @click="isAnimation=!isAnimation" role="button">跳过动画</span>
                                </div>
                            </div>
        
                            <!-- 幸运物选择区 -->
                            <div class="d-flex justify-content-between ps-1 px-sm-3 pt-3 pt-sm-3 pb-3 pb-sm-4">
                                <div v-for="(item,i) of goods" :key="item" class="d-flex flex-column col col-sm-auto me-1 me-sm-0">
                                    <div :class="[(item.counter>0&&item.counter<limitred[0])||item.counter>limitred[1]?'text-danger':'text-info']" class="bg-dark w-100 rounded-5 fs-5 lh-sm moniter text-end px-1 me-2 mb-1">
                                        <label class="d-flex letter-spacing-_01">
                                            <span class="d-flex justify-content-end text-secondary text-opacity-25 flex-grow-1 w-0 overflow-hidden text-right"></span>
                                            <span>{{item.counter}}</span>
                                        </label>
                                    </div>
                                    <div :id="'btnLucky'+(i+1)" class="flex-grow-1 d-flex flex-column align-items-center rounded-3 bg-warning text-center p-sm-1 btn-3d border border-warning" @click="addScore(item.code)" role="button">
                                        <div class="flex-grow-1 mb-1 d-flex align-items-center w-sm-3rem">
                                            <img class="img-fluid" :src="require('@/assets/images/room/tiger/'+item.code+'.png')" alt="">
                                        </div>
                                        <label class="fw-bold lh-1">×{{item.rate}}</label>
                                    </div>
                                </div>
                            </div>
        
                            <!-- 提交按钮 -->
                            <div class="d-flex flex-column px-1 px-sm-3 pb-2">
                                <div class="d-flex justify-content-between border border-2 border-dark rounded-pill pb-3 pb-sm-4 px-2 bg-info" style="box-shadow: 0 0 0.3rem 0 #000;">
                                    <div id="btnStart" @click="start" class="text-light bg-danger border border-danger rounded-circle w-4rem h-4rem fs-5 fw-bold d-flex justify-content-center align-items-center btn-start" role="button">
                                        <label>开始</label>
                                    </div>
                                    <div id="btnReset" @click="reset" class="text-light bg-warning border border-warning rounded-circle w-4rem h-4rem fs-5 fw-bold d-flex justify-content-center align-items-center btn-reset" role="button">
                                        <label>重置</label>
                                    </div>
                                    <div @click="keepOn(1)" class="text-light bg-danger border border-danger rounded-circle w-4rem h-4rem fs-5 fw-bold d-flex flex-column lh-1 justify-content-center align-items-center btn-start" role="button">
                                        <div>续押</div>
                                    </div>
                                    <div @click="keepOn(5)" class="text-light bg-danger border border-danger rounded-circle w-4rem h-4rem fs-5 fw-bold d-flex flex-column lh-1 justify-content-center align-items-center btn-start" role="button">
                                        <div>续押</div>
                                        <div>×5</div>
                                    </div>
                                    <div @click="keepOn(10)" class="text-light bg-danger border border-danger rounded-circle w-4rem h-4rem fs-5 fw-bold d-flex flex-column lh-1 justify-content-center align-items-center btn-start" role="button">
                                        <div>续押</div>
                                        <div>×10</div>
                                    </div>
                                    <div @click="keepOn(20)" class="text-light bg-danger border border-danger rounded-circle w-4rem h-4rem fs-5 fw-bold d-flex flex-column lh-1 justify-content-center align-items-center btn-start" role="button">
                                        <div>续押</div>
                                        <div>×20</div>
                                    </div>
                                </div>
                            </div>
        
                        </div>
                    </div>
                    
                    
                </div>
            </div>
    
            <canvas id="abc" class="position-absolute top-0 start-0 d-none"></canvas>
    
            <!-- 换台区 -->
            <change-room :room-list="roomList" :count-list="countList" :pearl-obj="pearlObj" @get-room-pearl="getRoomPearl"></change-room>
    
            <!-- 音效 -->
            <audio-player v-show="false" ref="sceneAudioPlayer" @pause="(!$refs.sceneAudioPlayer.$refs.audio.ended)&&$refs.sceneAudioPlayer.play()" :isLoop="false" :isAutoPlayNext="false" :muted="!audioSwitch.startAudio" :audio-list="sceneAudioList.map(elm => elm.url)" />

            <!-- 规则 -->
            <div v-if="isShowRule" class="d-flex d-sm-none justify-content-center align-items-center position-absolute top-0 bottom-0 end-0 start-0 bg-dark bg-opacity-50 index-1051">
				<div class="d-flex flex-column rounded-3 overflow-hidden shadow mh-100 mx-5">
					<div class="bg-navy d-flex justify-content-between py-2 px-3 text-light">
						<span>规则说明</span>
						<i class="bi bi-x-octagon" @click="isShowRule=false"></i>
					</div>
                    <div class="p-3 bg-blue d-flex flex-wrap text-light overflow-y-scroll scroll-none">
                        <div class="text-light">
                            <!-- 玩法说明 -->
                            <div class="d-flex flex-column col">
                                <div class="fw-bold mb-1 fs-4 text-center text-shadow-white text-primary">
                                    <i class="bi bi-controller"></i>
                                    <i class="ms-1">玩法说明</i>
                                </div>
                                <div class="rounded-5 p-2 border-shadow flex-grow-1 d-flex">
                                    <div class="rounded-5 w-100 overflow-y-scroll scroll-none">
                                        <div class="d-flex flex-column small mb-1">
                                            <div class="px-2 position-relative mb-1">
                                                <div class="bg-primary border border-2 fw-bold text-light text-center w-100 pt-4">
                                                    <label class="bg-primary border-2 border-start border-end w-100 position-absolute translate-middle-y start-0 top-50 rounded-pill lh-1">游戏规则</label>
                                                </div>
                                            </div>
                                            <div class="text-info small fw-bold px-2">
                                                <i class="bi bi-star-fill me-1"></i>
                                                <span>玩家在8种幸运物中选中任意项，每按一次，对应计数器递增的数为选中的基数数值。每种幸运物下方数字为幸运物被开中时的中奖倍率（注：转盘中有标注的以转盘中的为准）。幸运物选择完成后，按下“开始”按钮，转盘运作随机产生一种幸运物并计算本轮得分。上一轮结束后，若希望保持选中的幸运物及计数器不变的情况下继续押注，则按下对应次数的“续押”按钮。否则可按下“重置”按钮置零所有幸运物计数器。</span>
                                            </div>
                                            <div class="text-info small fw-bold px-2">
                                                <i class="bi bi-star-fill me-1"></i>
                                                <span>本轮得分=被开中的幸运物倍率×幸运物对应计数器计数<span class="text-danger">（所有下注积分不予返还）</span></span>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column small mb-1">
                                            <div class="px-2 position-relative mb-1">
                                                <div class="bg-primary border border-2 fw-bold text-light text-center w-100 pt-4">
                                                    <label class="bg-primary border-2 border-start border-end w-100 position-absolute translate-middle-y start-0 top-50 rounded-pill lh-1">快捷操作</label>
                                                </div>
                                            </div>
                                            <div class="text-info small fw-bold px-2">
                                                <i class="bi bi-star-fill me-1"></i>
                                                <span>8种幸运物从左到右快捷分别对应键盘按键1~8。</span>
                                            </div>
                                            <div class="text-info small fw-bold px-2">
                                                <i class="bi bi-star-fill me-1"></i>
                                                <span>“开始”按钮对应键盘按键“回车(Enter)”。</span>
                                            </div>
                                            <div class="text-info small fw-bold px-2">
                                                <i class="bi bi-star-fill me-1"></i>
                                                <span>“重置”按钮对应键盘按键“0”。</span>
                                            </div>
                                            <div class="text-info small fw-bold px-2">
                                                <i class="bi bi-star-fill me-1"></i>
                                                <span>基数设置可用键盘“+”“-”变更大小。</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>

            <!-- 排行榜 -->
            <div v-if="isShowBoard" class="d-flex d-sm-none justify-content-center align-items-center position-absolute top-0 bottom-0 end-0 start-0 bg-dark bg-opacity-50 index-1051">
				<div class="d-flex flex-column rounded-3 overflow-hidden shadow mh-100 mx-5 w-100">
					<div class="bg-navy d-flex justify-content-between py-2 px-3 text-light">
						<span>排行榜</span>
						<i class="bi bi-x-octagon" @click="isShowBoard=false"></i>
					</div>
                    <div class="p-3 bg-blue d-flex flex-wrap text-light overflow-y-scroll scroll-none">
                        <div class="text-light w-100">
                            <!-- 排行榜 -->
                            <div class="d-flex flex-column col">
                                <div class="fw-bold mb-1 fs-4 text-center text-shadow-white">
                                    <i class="bi bi-stars text-warning"></i>
                                    <i class="ms-1 me-2 text-danger">排行榜</i>
                                    <i class="bi bi-stars text-warning"></i>
                                </div>
                                <div class="rounded-5 p-2 border-shadow flex-grow-1 d-flex text-nowrap">
                                    <div class="bg-dark rounded-5 w-100 p-2 overflow-y-scroll scroll-none">
                                        <div v-for="(item,i) of sort" :key="item" :class="{'bg-warning':i==0,'bg-silver':i==1,'bg-coppery':i==2,'bg-info':i>2}" class="d-flex align-items-center mb-1 p-1 rounded-3 fw-bold bg-3d">
                                            <div class="position-relative">
                                                <i class="d-flex bi bi-award-fill fs-3" :class="{'text-yellow':i==0,'text-secondary':i==1,'text-saddlebrown':i==2,'invisible':i>2}"></i>
                                                <label class="position-absolute start-0 end-0 text-center small" :class="{'text-warning top-0':i==0,'text-silver top-0':i==1,'text-coppery top-0':i==2,'text-primary text-opacity-75 text-shadow-white fs-6 top-50 translate-middle-y':i>2}">{{i+1}}</label>
                                            </div>
                                            <span class="flex-grow-1 px-2" :class="[i<=2?'text-danger':'text-dark']">{{item.user}}</span>
                                            <div class="d-flex bg-dark bg-opacity-25 rounded-pill px-1">
                                                <i class="bi bi-coin me-1 text-yellow"></i>
                                                <span class="text-light">{{item.score}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
				</div>
			</div>

        </div>
    </div>
</template>
<script>
import {test} from '@/assets/js/common.js'
import { COMMAND, IS_DEBUG,LAYER_TYPE,GAME_ID,RESULT,MUTATION_TYPE,GAME_CODE } from "@/assets/js/config.js";
import navbar from '@/components/public/navbar.vue';
import { useLayer } from '@/assets/js/useLayer';
import changeRoom from '@/components/public/changeRoom.vue'
export default {
    components: { 
        changeRoom,
        navbar
    },
    props: {
        hasChatMsg:false,//是否有未读的客服消息
        isPageReady:false,//页面是否加载完毕
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
    },
    data() {
        return {
            countList:[],//路单统计列表
            pearlObj: {
                id:0,//台面id
                pearl:[]//路单数据
            },//要重绘路单的对象
            luckySetting:[//位置项设置
                //1~7
                {
                    code:"orange",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"bell",//铃铛
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"bar",
                    rate:25,//倍率
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"bar",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"apple",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"apple",
                    rate:2,//倍率
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"melon",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                //8~12
                {
                    code:"watermelon",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"watermelon",
                    rate:10,//倍率
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"lucky",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"apple",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"orange",
                    rate:5,//倍率
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                //13~19
                {
                    code:"orange",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"bell",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"seven",
                    rate:20,//倍率
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"seven",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"apple",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"melon",
                    rate:5,//倍率
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"melon",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                //20~24
                {
                    code:"star",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"star",
                    rate:15,//倍率
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"lucky",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"apple",
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
                {
                    code:"bell",
                    rate:7,//倍率
                    light:false,//是否亮灯
                    flicker:false,//是否闪烁
                    count:0,//当前叠加个数
                },
            ],
            gameState:0,//游戏状态（0:空闲 1:跑灯中 2:结算中）
            radixArr:[1,10,100,1000],//基数
            radix:1,//基数设置
            sceneAudioList:[
                {
                    url: require('@/assets/media/tiger/win.mp3')//开中
                },
                {
                    url: require('@/assets/media/tiger/fail.mp3')//没开中
                },
                {
                    url:require('@/assets/media/tiger/di.mp3')//结算计数
                },
                {
                    url: require('@/assets/media/tiger/add.mp3')//选择幸运物
                },
                {
                    // url: require('@/assets/media/tiger/ding.mp3')//跑灯
                    url: require('@/assets/media/tiger/go.mp3')//跑灯
                },
                {
                    url: require('@/assets/media/tiger/start.mp3')//开始按钮
                },
                {
                    url: require('@/assets/media/tiger/lucky.mp3')//lucky
                },
                {
                    url: require('@/assets/media/tiger/salvo.mp3')//礼炮
                },
                {
                    url: require('@/assets/media/tiger/cheer.mp3')//欢呼
                },
            ],//场景音效
            goods:[
                {
                    code:"bar",//代号
                    rate:50,//倍率
                    counter:0,//计数器
                    seat:[3,4]//包含位置
                },
                {
                    code:"seven",//代号
                    rate:40,//倍率
                    counter:0,//计数器
                    seat:[15,16]//包含位置
                },
                {
                    code:"star",//代号
                    rate:30,//倍率
                    counter:0,//计数器
                    seat:[20,21]//包含位置
                },
                {
                    code:"watermelon",//代号
                    rate:20,//倍率
                    counter:0,//计数器
                    seat:[8,9]//包含位置
                },
                {
                    code:"bell",//代号
                    rate:15,//倍率
                    counter:0,//计数器
                    seat:[2,14,24]//包含位置
                },
                {
                    code:"melon",//代号
                    rate:10,//倍率
                    counter:0,//计数器
                    seat:[7,18,19]//包含位置
                },
                {
                    code:"orange",//代号
                    rate:10,//倍率
                    counter:0,//计数器
                    seat:[1,12,13]//包含位置
                },
                {
                    code:"apple",//代号
                    rate:5,//倍率
                    counter:0,//计数器
                    seat:[5,6,11,17,23]//包含位置
                },
            ],//物品对象
            sort:[],//排行榜
            history:[],//历史记录
            bounsWin:0,//赢得积分
            currRound:0,//本轮积分
            lastGoods:[],//缓存选中的幸运物
            keepOnCount:0,//续押次数
            isAnimation:true,//是否显示动画
            limitred:[0,0],//限红
            isShowRule:false,//竖屏是否显示规则说明
            isShowBoard:false,//竖屏是否显示排行榜
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        roomInfo() {
            return this.$store.state.roomInfo;
        },
        tabGame(){
            return this.$store.state.tabGame;
        },
        audioSwitch(){
            return this.$store.state.audioSwitch;
        },
        roomList(){
            return this.$store.state.roomList;
        }
    },
    mounted() {
        // test();
        // this.fireworks();
        document.addEventListener('keyup',(e)=>{
            let key=e.key;
            if(key=="Enter"){
                this.start();
                document.getElementById("btnStart").classList.remove("active");
            }else if(key=="0"){
                this.reset();
                document.getElementById("btnReset").classList.remove("active");
            }else if(["1","2","3","4","5","6","7","8"].indexOf(key)!=-1){
                document.getElementById("btnLucky"+key).classList.remove("active");
            }
        })
        document.addEventListener('keydown',(e)=>{
            let key=e.key;
            if(key=="Enter"){
                document.getElementById("btnStart").classList.add("active");
            }else if(key=="0"){
                document.getElementById("btnReset").classList.add("active");
            }else if(["1","2","3","4","5","6","7","8"].indexOf(key)!=-1){
                this.addScore(this.goods[key-1].code);
                document.getElementById("btnLucky"+key).classList.add("active");
            }else if(key=="+"||key=="-"){
                let index = this.radixArr.indexOf(this.radix);
                let direction=key=="+"?1:-1;
                this.radixArr[index+direction]&&(this.radix=this.radixArr[index+direction]);
            }
        })
        this.init();
        this.setWsMessage();
    },
    unmounted() {
        delete this.$root.wsMsgObj["tiger"];
    },
    methods: {
        init(){
            //获取限红
            this.$emit('ws-send', COMMAND.slot_getlimitred,this.userInfo.id,this.userInfo.guid);
            //获取排行榜
            this.$emit('ws-send', COMMAND.fruit_order, this.userInfo.id, this.userInfo.guid);
            //获取历史记录
            this.$emit('ws-send', COMMAND.fruit_log, this.userInfo.id, this.userInfo.guid);
            //获取台面列表
            this.$emit("ws-send",COMMAND.all_getroomlist, this.roomInfo.game,this.userInfo.id, this.userInfo.guid);
        },
        /**
         * 设置websocket响应事件
         */
        setWsMessage(){
            this.$root.wsMsgObj["tiger"]=async (data)=>{
                switch (data.command) {
                    case COMMAND.fruit_order:
                        this.sort=data.list;
                        break;
                    case COMMAND.fruit_log:
                        this.history=data.list;
                        if(this.history.length>0){//有历史记录则把初始灯设置为最后一局的位置
                            this.luckySetting[this.history[0]-1].light=true;
                        }else{
                            this.luckySetting[0].light=true;//默认把初始灯设置到第一个位置
                        }
                        break;
                    case COMMAND.fruit_submitbet:
                        if(data.result==1){
                            this.gameState=1;
                            await new Promise(async (resolve)=>{
                                //遍历开中的所有位置
                                for (let i = 0; i < data.index.length; i++) {
                                    await new Promise(async (res)=>{
                                        //跑灯
                                        await this.runLight(
                                            this.history.length>0?this.history[0]:1,
                                            data.index[i],
                                            (this.keepOnCount>0||!this.isAnimation||(this.luckySetting[data.index[0]-1].code=="lucky"&&i>0))?true:false,//续押或者跳过动画或者 无续押并且第一个开中位为Lucky时的第二个位置开始时，都为短距离跑灯
                                            (i>0||this.keepOnCount>0)?false:true);//i>0(开中lucky)或者续押状态下需要保持之前的灯不关掉
                                        //闪烁
                                        this.luckySetting[data.index[i]-1].flicker=true;
                                        setTimeout(() => {
                                            this.luckySetting[data.index[i]-1].flicker=false;
                                        }, 2100);
                                        //追加记录
                                        this.history.unshift(data.index[i]);
                                        //结算
                                        this.gameState=2;
                                        let goods=this.goods.find(v=>v.code==this.luckySetting[data.index[i]-1].code);//根据位置找对应幸运物
                                        if(!goods){//找不到
                                            if(this.luckySetting[data.index[i]-1].code){//代号为Lucky
                                                this.$refs.sceneAudioPlayer.currentPlayIndex = 6;
                                                this.$nextTick(()=>{
                                                    this.$refs.sceneAudioPlayer.play();
                                                    setTimeout(() => {
                                                        res();
                                                    }, 1600);
                                                })
                                            }
                                        }else{//找到了
                                            if(goods.counter>0){//如果开中位置的幸运物为选中的幸运物
                                                this.$refs.sceneAudioPlayer.currentPlayIndex = 0;
                                                this.$nextTick(()=>{
                                                    this.$refs.sceneAudioPlayer.play();//奏乐庆祝
                                                    setTimeout(async () => {
                                                        //计算本轮积分
                                                        await this.scoreCounter(goods.counter*(this.luckySetting[data.index[i]-1].rate||goods.rate),1);//递增时：当前得分=计数器计数*幸运物倍率
                                                        //本轮积分进入赢得积分
                                                        if(i==data.index.length-1){//遍历完所有开中位置
                                                            await new Promise((resolve)=>{
                                                                setTimeout(async () => {
                                                                    await this.scoreCounter(this.currRound,-1);//递减时：当前得分=本轮总得分
                                                                    resolve();
                                                                }, 1600);
                                                            })
                                                        }

                                                        setTimeout(() => {
                                                            res();
                                                        }, 1000);
                                                    }, 1800);
                                                })
                                            }else{//如果开中位置的幸运物没选中
                                                if(i==data.index.length-1&&!data.index.some(v=> this.goods.find(g=>g.counter>0&&g.seat.indexOf(v)!=-1))){//当前为最后一项并且所有项都不中、        如果不是非续押并且第一项为Lucky则响
                                                    this.$refs.sceneAudioPlayer.currentPlayIndex = 1;
                                                    this.$nextTick(()=>{
                                                        this.$refs.sceneAudioPlayer.play();
                                                    })
                                                }
                                                setTimeout(() => {
                                                    res();
                                                }, 1000);
                                            }
                                        }
                                        
                                    })
                                }
                                resolve();
                            })
                            if(this.keepOnCount<=1){//续押期间不清除计数器，等于1说明为最后一次，即需要清除计数器
                                //有赢，整点庆祝效果庆祝庆祝
                                if(data.score>0&&this.isAnimation){
                                    await this.salvo();
                                }

                                this.lastGoods=JSON.parse(JSON.stringify(this.goods));
                                this.goods.forEach(v=>v.counter=0);
                            }
                            IS_DEBUG&&console.log("结算结束");
                            this.gameState=0;

                            if(this.keepOnCount>0){//续押状态
                                this.keepOnCount-=1;
                                this.keepOnCount>0&&this.start();
                            }
                        }else if(data.result==-2){
                            useLayer({str:"积分不足！"});
                        }else if(data.result==-3){
                            useLayer({str:"台面不存在！"});
                        }else if(data.result==-4){
                            useLayer({str:"超出限红！"});
                        }
                        break;
                    case COMMAND.all_entertable:
                        if(data.result!=RESULT.enterTable.success){
                            useLayer({str:this.$t("home.goRoomErr")});
                            return;
                        }
                        let table=this.roomList.find(v=>v.id==data.id);
                        this.$store.commit({
                            type:MUTATION_TYPE.setRoomInfo,
                            roomInfo:table
                        })
                        if(table.id==-2){
                            this.$root.goPage("slotscroll");
                        }else{
                            this.$root.goPage(GAME_CODE[this.tabGame]);
                        }
                        break;
                    case COMMAND.all_getroompearl:
                        this.pearlObj={
                            id:data.id,
                            pearl:data.pearl
                        }
                        this.countList[data.id]=data.count;
                        break;
                    case COMMAND.cor_gameresult:
                        typeof this.roomList.find(v=>v.id==data.id)!="undefined"&&this.getRoomPearl(data.id,this.$root.columnList[data.id]);//台面存在且可见
                        break;
                    case COMMAND.bac_gameresult:
                        //处理当前cor台但切换台面为bac台时数据不更新问题，临时解决
                        this.tabGame==GAME_ID.bac&&this.$emit("ws-send",COMMAND.all_getroompearl, this.tabGame,data.id,200,"list", this.userInfo.id, this.userInfo.guid);
                        break;
                    case COMMAND.slot_getlimitred:
                        this.limitred=data.limitred;
                        break;
                }
            }
        },
        /**
         * 积分计数器
         * @param {Number} score 分数
         * @param {Number} direction 方向（1或-1）
         */
        scoreCounter(score,direction){
            // let step=Number(1+ (score>=999?"0".repeat(String(score).length-3):""));//正常情况下每次递增/减的值
            let step=Number(1+ (score>=99?"0".repeat(String(score).length-2):""));//正常情况下每次递增/减的值
            let count=Math.ceil(score/step);//递增/减次数
            let lastRound=this.currRound;
            let lastBounsWin=this.bounsWin;
            return new Promise(async (allResolve)=>{
                for (let j = 1; j <= count; j++) {
                    j=this.isAnimation?j:count;//不显示动画时直接将次数置为最后一次
                    await new Promise((resolve)=>{
                        setTimeout(() => {
                            this.currRound=(direction==1?lastRound+(j*step>score?score:(j*step)):((count-j)*step>score?score:((count-j)*step)));//当前积分=次数*步长
                            if(direction==-1){//递减时需要同时递增赢得积分
                                let beforeBounsWin=this.bounsWin;
                                this.bounsWin= this.$np.plus(lastBounsWin,this.$np.minus(score,this.currRound));
                                this.$store.commit({
                                    type:MUTATION_TYPE.setUserInfo,
                                    userInfo:{
                                        realCoin: this.$np.plus(this.userInfo.realCoin,this.$np.minus(this.bounsWin,beforeBounsWin))
                                    }
                                })
                            }
                            this.$refs.sceneAudioPlayer.$refs.audio.currentTime = 0;
                            this.$refs.sceneAudioPlayer.currentPlayIndex = 2;
                            this.$refs.sceneAudioPlayer.$refs.audio.volume=0.1;
                            this.$nextTick(()=>{
                                this.$refs.sceneAudioPlayer.play();
                                resolve();
                            })
                        },10);
                    })
                }
                allResolve();
            }).then(()=>{
                this.$refs.sceneAudioPlayer.$refs.audio.volume=1;
            })
        },
        /**
         * 开始
         */
        start(){
            let str="";
            for (let i = 0; i < this.goods.length; i++) {
                if(this.goods[i].counter>0){
                    str+=((this.goods.length-i)+":"+this.goods[i].counter+",");
                    if(this.goods[i].counter<this.limitred[0]||this.goods[i].counter>this.limitred[1]){
                        useLayer({str:"存在与限红不匹配的幸运物！"});
                        return;
                    }
                }
            }
            str=str.substring(0,str.length-1);
            if(str==""){
                useLayer({str:"请先选择幸运物再开始！"});
                return;
            }
            if(this.gameState==1){
                useLayer({str:"开奖中，请稍后！"});
                return;
            }else if(this.gameState==2){
                useLayer({str:"结算中，请稍后！"});
                return;
            }
            this.$refs.sceneAudioPlayer.currentPlayIndex = 5;
            this.$nextTick(()=>{
                this.$refs.sceneAudioPlayer.play();
            })
            //提交数据
            this.$emit('ws-send', COMMAND.fruit_submitbet, this.roomInfo.game,0,str, this.userInfo.id, this.userInfo.guid);
        },
        /**
         * 跑灯
         * @param {Number} startNum 开始位置
         * @param {Number} endNum 结束位置
         * @param {boolean} isShort 是否以最短距离跑灯
         * @param {boolean} isCloseLight 是否关掉当前开着的灯
         */
        runLight(startNum,endNum,isShort,isCloseLight){
            return new Promise(async (allResolve)=>{
                let lightSeatArr=JSON.parse(JSON.stringify(this.luckySetting));//亮灯的位置
                let direction=1;//方向，1为顺时针，-1为逆时针
                let itemCount=this.luckySetting.length;//一圈的总个数
                // let startNum=this.luckySetting.findIndex(v=>v.light)+1;//找到当前亮灯的位置（开始位置）
                // let endNum=Math.floor(Math.random()*itemCount)+1;//生成结束位置（1~一圈总个数）
                let runCount=(endNum>startNum?Math.abs(startNum-endNum):(itemCount-Math.abs(startNum-endNum)));//跑5圈+(结束位置大于等于开始位置：开始位置与结束位置的位置差，否则为总位置数-位置差)=总共需要位移的个数
                (!isShort)&&(runCount+=5*itemCount);//非跳过动画则跑5圈+(结束位置大于等于开始位置：开始位置与结束位置的位置差，否则为总位置数-位置差)=总共需要位移的个数
                let endNumber=runCount+startNum;//基于当前开始位置和圈数计算的最终位置号
                let totalTime=5000;//总消耗时间为5s
                IS_DEBUG&&console.log("需位移"+runCount+"个格子");
                IS_DEBUG&&console.log("从"+startNum+"号位开始");
                IS_DEBUG&&console.log("在"+endNum+"号位结束");
                IS_DEBUG&&console.time();
                if(!isShort){
                    this.$refs.sceneAudioPlayer.currentPlayIndex = 4;
                    this.$nextTick(()=>{
                        this.$refs.sceneAudioPlayer.play();
                    })
                }
                for (let i = startNum; i < endNumber; i++) {
                    i=this.isAnimation?i:(endNumber-1);//不显示动画时直接将当前格置于最右一个
                    //走一格
                    await new Promise((resolve)=>{
                        // let currTime=(i-startNum<=7)?550-75*(i-startNum):((endNumber-i<=7)?25+550-75*(endNumber-i):25);//本次一格的时间
                        // let currTime=(i-startNum<=7)?300-30*(i-startNum):((endNumber-i<=7)?25+250-30*(endNumber-i):24);//本次一格的时间
                        let currTime=(i-startNum<=7)?200-5*(i-startNum):((endNumber-i<=8)?200-5*(endNumber-i):totalTime/(runCount-16));//本次一格的时间
                        (i-startNum<=7)&&(totalTime-=2*currTime);
                        isShort&&(currTime=25);
                        setTimeout(() => {
                            // this.$refs.sceneAudioPlayer.$refs.audio.currentTime = 0.0248;
                            // this.$refs.sceneAudioPlayer.currentPlayIndex = 4;
                            // this.$nextTick(()=>{
                            //     this.$refs.sceneAudioPlayer.play();
                            // })
                            // this.luckySetting.find(v=>v.light).light=false;//关掉上一个灯
                            this.luckySetting.forEach((v,index)=>{
                                if(isCloseLight){
                                    v.light=false;
                                    v.count=0;
                                }else{
                                    (!lightSeatArr[index].light)&&(v.light=false);//跑灯的时候关掉不是当前亮灯位置的灯
                                }
                            });//如果要关掉之前的灯则关掉所有亮的灯，否则关掉之前亮的灯中不存在的灯
                            this.luckySetting[i%itemCount].light=true;//开启新的灯
                            (!isCloseLight)&&(i==endNumber-1)&&(this.luckySetting[i%itemCount].count+=1);//不关闭当前开的灯说明会产生叠加，则累加叠加数
                            resolve();
                        },currTime);
                    })
                }
                
                IS_DEBUG&&console.timeEnd();
                IS_DEBUG&&console.log("跑灯结束");
                allResolve();
            })
        },
        /**
         * 烟花
         */
        fireworks(){
            var raf = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (callback) { window.setTimeout(callback, 1000 / 60); };
            var Boom = function(x,r,c,boomArea,shape){
                this.booms = [];
                this.x = x;
                this.y = (canvas.height+r);
                this.r = r;
                this.c = c;
                this.shape = shape || false;
                this.boomArea = boomArea;
                this.theta = 0;
                this.dead = false;
                this.ba = parseInt(getRandom(80 , 200));
            }
            Boom.prototype = {
                _paint:function(){
                    ctx.save();
                    ctx.beginPath();
                    ctx.arc(this.x,this.y,this.r,0,2*Math.PI);
                    ctx.fillStyle = this.c;
                    ctx.fill();
                    ctx.restore();
                },
                _move:function(){
                    var dx = this.boomArea.x - this.x , dy = this.boomArea.y - this.y;
                    this.x = this.x+dx*0.01;
                    this.y = this.y+dy*0.01;

                    if(Math.abs(dx)<=this.ba && Math.abs(dy)<=this.ba){
                        if(this.shape){
                            this._shapBoom();
                        }
                        else this._boom();
                        this.dead = true;
                    }
                    else {
                        this._paint();
                    }
                },
                _drawLight:function(){
                    ctx.save();
                    ctx.fillStyle = "rgba(255,228,150,0.3)";
                    ctx.beginPath();
                    ctx.arc(this.x , this.y , this.r+3*Math.random()+1 , 0 , 2*Math.PI);
                    ctx.fill();
                    ctx.restore();
                },
                _boom:function(){
                    var fragNum = getRandom(30 , 200);
                    var style = getRandom(0,10)>=5? 1 : 2;
                    var color;
                    if(style===1){
                        color = {
                            a:parseInt(getRandom(128,255)),
                            b:parseInt(getRandom(128,255)),
                            c:parseInt(getRandom(128,255))
                        }
                    }

                    var fanwei = parseInt(getRandom(300, 400));
                    for(var i=0;i<fragNum;i++){
                        if(style===2){
                            color = {
                                a:parseInt(getRandom(128,255)),
                                b:parseInt(getRandom(128,255)),
                                c:parseInt(getRandom(128,255))
                            }
                        }
                    }
                },
                _shapBoom:function(){
                    putValue(ocas , octx , this.shape , 5, function(dots){
                        for(var i=0;i<dots.length;i++){
                            color = {a:dots[i].a,b:dots[i].b,c:dots[i].c}
                        }
                    })
                }
            }
            var bigbooms = [];
            let getRandom=(a , b)=>{
                return Math.random()*(b-a)+a;
            }

            let canvas=document.getElementById("abc");
            let parentStyle=getComputedStyle(canvas.parentNode);
            let ctx=canvas.getContext('2d');
            canvas.width=parentStyle.width.replace('px','');
            canvas.height=parentStyle.height.replace('px','');
            var lastTime = new Date();
            

            var fn=()=>{
                ctx.save();
                ctx.fillStyle = "rgba(0,5,24,0.2)";
                ctx.fillRect(0,0,canvas.width,canvas.height);
                // ctx.clearRect(0, 0, canvas.width, canvas.height);
                console.log(bigbooms)
                ctx.restore();

                var newTime = new Date();
                if(newTime-lastTime>500+(window.innerHeight-767)/2){
                    var random = Math.random()*100>2?true:false;
                    var x = getRandom(canvas.width/5 , canvas.width*4/5);
                    var y = getRandom(50 , 200);
                    if(random){
                        var bigboom = new Boom(getRandom(canvas.width/3,canvas.width*2/3) ,2,"#FFF" , {x:x , y:y});
                        bigbooms.push(bigboom)
                    }
                    else {
                        var bigboom = new Boom(getRandom(canvas.width/3,canvas.width*2/3) ,2,"#FFF" , {x:canvas.width/2 , y:200} , document.querySelectorAll(".shape")[parseInt(getRandom(0, document.querySelectorAll(".shape").length))]);
                        bigbooms.push(bigboom)
                    }
                    lastTime = newTime;
                }

                bigbooms.forEach(function(index){
                    var that = index;
                    if(!index.dead){
                        index._move();
                        index._drawLight();
                    }
                    else{
                        index.booms.forEach(function(index){
                            if(!index.dead) {
                                index.moveTo(index);
                            }
                            else if(index === that.booms.length-1){
                                bigbooms[bigbooms.indexOf(that)] = null;
                            }
                        })
                    }
                });

                raf(fn);

            }

            fn();

            // var newTime = new Date();
            // if(newTime-lastTime>500+(window.innerHeight-767)/2){
            //     var random = Math.random()*100>2?true:false;
            //     var x = getRandom(canvas.width/5 , canvas.width*4/5);
            //     var y = getRandom(50 , 200);
            //     if(random){
            //         var bigboom = new Boom(getRandom(canvas.width/3,canvas.width*2/3) ,2,"#FFF" , {x:x , y:y});
            //         bigbooms.push(bigboom)
            //     }
            //     else {
            //         var bigboom = new Boom(getRandom(canvas.width/3,canvas.width*2/3) ,2,"#FFF" , {x:canvas.width/2 , y:200} , document.querySelectorAll(".shape")[parseInt(getRandom(0, document.querySelectorAll(".shape").length))]);
            //         bigbooms.push(bigboom)
            //     }
            //     lastTime = newTime;
            //     console.log(bigbooms)
            // }

            // stars.foreach(function(){
            //     this.paint();
            // })

            // drawMoon();

            // bigbooms.foreach(function(index){
            //     var that = this;
            //     if(!this.dead){
            //         this._move();
            //         this._drawLight();
            //     }
            //     else{
            //         this.booms.foreach(function(index){
            //             if(!this.dead) {
            //                 this.moveTo(index);
            //             }
            //             else if(index === that.booms.length-1){
            //                 bigbooms[bigbooms.indexOf(that)] = null;
            //             }
            //         })
            //     }
            // });
            
            // raf(animate);
        },
        /**
         * 打炮
         */
        salvo(){
            return new Promise((resolve)=>{
                setTimeout(() => {
                    this.$refs.sceneAudioPlayer.currentPlayIndex = 7;
                    this.$nextTick(()=>{
                        this.$refs.sceneAudioPlayer.play();
                        setTimeout(() => {
                            this.$refs.sceneAudioPlayer.currentPlayIndex = 8;
                            this.$nextTick(async ()=>{
                                window.confetti({
                                    particleCount: 200,//发射的纸屑数量
                                    angle: 45,//发射纸屑的角度
                                    spread:90,//纸屑离中心多远
                                    origin: {
                                        x: 0
                                    },//从左边开始发射纸屑
                                    shapes:['star', 'circle', 'square'],
                                    scalar:1.5,
                                    drift:.5,//纸屑右飘
                                });
                                window.confetti({
                                    particleCount: 200,//发射的纸屑数量
                                    angle: 135,//发射纸屑的角度
                                    spread:90,//纸屑离中心多远
                                    origin: {
                                        x: 1
                                    },//从右边开始发射纸屑
                                    shapes:['star', 'circle', 'square'],
                                    scalar:1.5,
                                    drift:-.5,//纸屑左飘
                                });
                                this.$refs.sceneAudioPlayer.play();
                                resolve();
                            })
                        },300);
                    })
                }, 300);
            })
        },
        /**
         * 追加积分
         */
        addScore(code){
            if(this.gameState==0){
                let good = this.goods.find(v=>v.code==code);
                if(Number(this.userInfo.realCoin)<this.radix){
                    useLayer({str:"积分不足！"});
                    return;
                }
                // if(this.goods.reduce((prev,v)=>prev+v.counter,0)+this.radix>Number(this.limitred[1])){
                if(good.counter+this.radix>Number(this.limitred[1])){
                    useLayer({str:"超过单次最大限红！"});
                    return;
                }
                this.$refs.sceneAudioPlayer.$refs.audio.currentTime = 0;
                this.$refs.sceneAudioPlayer.currentPlayIndex = 3;
                this.$nextTick(()=>{
                    this.$refs.sceneAudioPlayer.play();
                })
                good.counter+=this.radix;
                this.deductScore(this.radix);//扣除积分
            }else{
                useLayer({str:"请等待本轮游戏结束后再选择！"});
            }
        },
        /**
         * 重置
         */
        reset(){
            let count=0;
            for (let i = 0; i < this.goods.length; i++) {
                count+=this.goods[i].counter;
            }
            this.$store.commit({
                type:MUTATION_TYPE.setUserInfo,
                userInfo:{
                    realCoin:this.$np.plus(this.userInfo.realCoin,count)
                }
            })//归还积分
            this.goods.forEach(v=>v.counter=0);
        },
        /**
         * 修改基数
         */
        changeRadix(radix){
            this.$refs.sceneAudioPlayer.$refs.audio.currentTime = 0;
            this.$refs.sceneAudioPlayer.currentPlayIndex = 2;
            this.$nextTick(()=>{
                this.$refs.sceneAudioPlayer.play();
            })
            this.radix=radix;
        },
        /**
         * 续押
         */
        keepOn(count){
            let needScore=this.lastGoods.reduce((prev,v)=>prev+v.counter,0)*count;//续押所需积分
            if(this.gameState==1){
                useLayer({str:"开奖中，请稍后！"});
                return;
            }else if(this.gameState==2){
                useLayer({str:"结算中，请稍后！"});
                return;
            }
            if(this.lastGoods.length==0){
                useLayer({str:"您还没有开始过，无法续押！"});
                return;
            }
            if(this.userInfo.realCoin<needScore){
                useLayer({str:"您的积分不足以续押"+count+"次"});
                return;
            }
            this.keepOnCount=count;
            this.goods.forEach((v,i)=>v.counter=this.lastGoods[i].counter);//恢复计数器
            this.deductScore(needScore);//扣除积分
            this.start();
            this.luckySetting.forEach((v,i)=>{
                if((i-1)!=this.history[0]){
                    v.light=false;//关掉所有灯
                }
                v.count=0;//清空叠加数
            });
        },
        /**
         * 扣除积分
         * @param {Number} score 要扣除的积分
         */
        deductScore(score){
            if(this.bounsWin>0){//有赢得积分，优先扣赢得积分
                if(this.bounsWin>score){//足够扣除
                    this.bounsWin-=score;
                }else{//不足够扣除
                    this.bounsWin=0;
                }
            }
            this.$store.commit({
                type:MUTATION_TYPE.setUserInfo,
                userInfo:{
                    realCoin:this.$np.minus(this.userInfo.realCoin,score)
                }
            })//先扣积分
        },
        /**
         * 获取台面路单
         * @param {Number} tableId 台面id
         * @param {Number} column 获取的数据列数
         */
        getRoomPearl(gameId,tableId,column){
            this.$emit("ws-send",COMMAND.all_getroompearl, gameId,tableId,this.tabGame==GAME_ID.bac?200:column,"list", this.userInfo.id, this.userInfo.guid);
        },
        /**
         * 倒计时
         * @param {Number} tableId 要计时的台面id
         * @param {Number} countdown 计时起始值
         */
        listCountdown(tableId,countdown){
            let room=this.roomList.find(v=>v.id==tableId);
            room.countdown=countdown;
            var timer = setInterval(()=>{
                if(room.countdown!=countdown){//两个值不一样说明台面计时被重新赋值了，应当停掉定时器
                    clearInterval(timer);
                    return;
                }
                if(room.countdown <= 0){
                    room.countdown=0;
                    clearInterval(timer);
                    return;
                }
                room.countdown--;
                countdown--;
            }, 1000);
        },
    },
    watch: {
        /**
         * 监听guid改变（断线重连）
         */
        "userInfo.guid"(newVal,oldVal){
            if(newVal!=oldVal&&newVal!=""){
                this.init();
            }
        },
    },
};
</script>